<template>
  <div class="content-wrapper users-manage">
    <a-form
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      :form="form"
      @submit="handleSubmit"
    >
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-item label="用户名">
            <a-input
              allowClear
              placeholder="请输入用户名"
              v-decorator="['username', { rules: [] }]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="邮箱">
            <a-input
              allowClear
              placeholder="请输入邮箱"
              v-decorator="[
                'email',
                { rules: [{ type: 'email', message: '邮箱格式有误' }] },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="角色">
            <a-select
              placeholder="请选择"
              v-decorator="['role', { initialValue: 'user' }]"
            >
              <a-select-option value="user">普通用户</a-select-option>
              <a-select-option value="member">会员</a-select-option>
              <a-select-option value="admin">管理员</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" style="text-align: right">
          <a-button type="primary" html-type="submit" @click="handleSubmit"
            >搜索</a-button
          >
          <a-button style="margin: 0 8px" @click="handleReset">重置</a-button>
        </a-col>
      </a-row>
    </a-form>

    <a-table rowKey="id" :columns="columns" :data-source="list">
      <a slot="username" slot-scope="text">{{ text }}</a>
      <span slot="create_time" slot-scope="text">{{ text | moment("YYYY-MM-DD HH:mm:ss") }}</span>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      form: this.$form.createForm(this, { name: "users-manage" }),
      list: [],
      columns: [
        {
          title: '用户名',
          dataIndex: 'username',
          key: 'username',
          scopedSlots: { customRender: 'username' },
          width: 120,
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          key: 'email',
          width: 100,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          width: 100,
        },
        {
          title: '角色',
          dataIndex: 'role',
          key: 'role',
          width: 100,
        },
        {
          title: '注册时间',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: { customRender: 'create_time' },
          width: 120,
        },
      ]
    };
  },
  mounted() {
    this.getUsersList();
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.getUsersList(values);
        }
      });
    },
    handleReset() {
      this.form.resetFields();
    },
    async getUsersList(values) {
      const { data } = await this.$store.dispatch('searchUsers', values);
      this.list = data;
    }
  },
};
</script>

<style lang="scss">
.users-manage {
  .ant-form {
    width: 100%;
  }
}
</style>
